<template>
    <section>
        <div class="row mx-0">
            <menu-categorias v-show="viewMenuCategorias" />
            <div class="col">
                <categoria-detalle />
            </div>
            <section-footer />
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
        menuCategorias: () => import('./components/menuCategorias.vue'),
        categoriaDetalle: () => import('./components/detalle.vue'),
    },
    computed:{
        ...mapGetters({
            viewMenuCategorias:'home/viewMenuCategorias'
        })
    }
}
</script>
<style lang="scss" scoped>

.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
  @media (min-width: 300px) and (max-width: 1024px) {
      height: calc(100vh - 96px);
  }
  @media (min-width: 300px) and (max-width: 1000px) {
      //display: none !important;
  }
}

.img-categories{
  box-shadow: 0px 3px 6px #00000029;
}

.tabs-mobile{
  display: none !important;
}

.banner-invitado{
    height: 113px;
    color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background-image: linear-gradient(to right, #612AD5,#9F4BFF, #F9C7FF,  #612AD5);
    .btn-registrarse{
        background-color: #FFFFFF;
        color: #612AD5;
        font-weight: 500;
        max-width: 182px;
        height: 44px;
        border-radius: 12px;
    }
}

.card-modificado{
    border: 1px solid #FF5806;
    background-color: #FFF3EA;
    //width: 312px;
    border-radius: 8px;
    height: 78px;
    .text-pedidos{
        height: 47px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn{
        display: flex;
        align-items: center;
        color: #FFFFFF;
        height: 32px;
        border-radius: 12px;
        width: 100%;
        background-color: #FF5806;
    }
}

@media (min-width: 300px) and (max-width: 815px) {

  .title-category{
    display: none !important;
  }  

  .img-categories{
    width: 213px;
    height: 86px;
  }
  
  .tabs-mobile{
    display: block !important;
  }

  .titles{
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .spacer{
    display: none !important;
  }

  .text-arrow{
    display: none !important;
  }
  .like-bar{
    display: none !important;
  }

  
}


@media (min-width: 300px) and (max-width: 1024px) {
    .col.home-page{
          height: calc(100vh - 106px) !important;
      }
}

</style>
